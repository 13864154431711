.App {
  font-family: 'Arial', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

.editable-div {
  min-height: 100px;
  border: 1px solid #ccc;
  padding: 1rem;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.output {
  margin-top: 2rem;
}

.output pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  border: 1px solid #ccc;
  padding: 1rem;
}

textarea{
  width: 100%;
}

.edit-items-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
}

.inline-select-component {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.quillSpan p { 
   margin: 0 ;
   padding: 0;
   display: inline;
}