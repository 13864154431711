body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.select-elements{
  	display: inline;
}

.select-elements .option{
	background-color: white!important;
}

.select-elements span.option{
	padding: 0 5px;
	margin: 0 5px;
	border: 1px solid black;
	cursor: pointer;
}

.select-elements span.selected{
  	background-color: lightgray!important;
}

.input-element{
	display: inline;
	margin: 0 5px;
}

.input-element input{
	border: none;
	border-bottom: 1px solid black;
	outline: none;
}

.hover-popup {
	position: absolute;
	top: 0;
	left: 0;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	padding: 8px;
	z-index: 10;
}

.hover-popup input {
	width: 100%;
	box-sizing: border-box;
	padding: 4px 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-bottom: 8px;
}

.hover-popup ul {
	list-style: none;
	padding: 0;
	margin: 0;
	max-height: 200px;
	overflow-y: auto;
}

.hover-popup li {
	padding: 4px 8px;
	cursor: pointer;
}

.hover-popup li:hover, .hover-popup li.selected {
	background-color: #f5f5f5;
}

